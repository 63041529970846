import React, { useState } from 'react';
import { isLimitedOrganization } from 'helpers/user';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import InviteCandidateIcon from 'images/icons/invite-candidate-blue.svg';
import VerticalDivider from '../../Shared/VerticalDivider';
import InviteCandidateModal from '../../Shared/Modals/InviteCandidateModal/InviteCandidateModal';
import { AssessmentTabProps } from '../../Assessment/assessment-tab-props';

const InviteCandidateButton = (
  props: AssessmentTabProps & { userDetails?: any }
): JSX.Element => {
  const { assessment, userDetails } = props;
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showAccountLimitModal, setShowAccountLimitModal] = useState<boolean>(
    false
  );
  const handleInviteClick = (): void => {
    if (isLimitedOrganization()) {
      setShowAccountLimitModal(true);
      return;
    }
    setShowInviteModal(true);
  };
  const label =
    assessment.purpose === 'ld' ? 'Invite Team Member' : 'Invite Candidate';
  return (
    <>
      <div
        className="button-bar-item"
        onClick={handleInviteClick}
        role="button"
        tabIndex={-1}
      >
        <img src={InviteCandidateIcon} alt={label || 'tooltip'} />
        <span className="button-bar-label">{label}</span>
      </div>
      <VerticalDivider className="button-bar-divider" />
      <InviteCandidateModal
        userDetails={userDetails}
        assessment={assessment}
        defaultDaysToComplete={assessment.days_to_complete}
        isShown={showInviteModal}
        setModalVisibility={setShowInviteModal}
        purpose={assessment.purpose}
        initialStep="invite-candidate"
      />
      <OrganizationLimitedModal
        isShown={showAccountLimitModal}
        setModalVisibility={setShowAccountLimitModal}
        title="Account Limited"
      />
    </>
  );
};

export default InviteCandidateButton;

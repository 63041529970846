import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import { isLimitedOrganization } from 'helpers/user';
import CardContainer from '../Shared/MainContainer/CardContainer';
import InviteCandidateModal from '../Shared/Modals/InviteCandidateModal/InviteCandidateModal';
import Button from '../Shared/Button';
import './nocandidates.scss';
import AddCandidateLogo from '../../images/add_candidates.svg';
import AtsLogo from '../../images/connect_to_ats.svg';
import ImportCandidateLogo from '../../images/import_candidates.svg';
import SuggestedCandidates from '../SuggestedCandidates';
import CopyLink from '../Shared/CopyLink';

const NoCandidates: React.FC<{
  assessment: any;
  userDetails?: any;
  name?: string;
  link?: string;
  hideAts?: boolean;
}> = ({
  assessment,
  userDetails,
  name = 'candidate',
  link,
  hideAts = false,
}) => {
  const history = useHistory();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const manageCandidatesExist = useSelector(
    (state: any) =>
      state.assessmentCandidate.participantsPaginator?.data?.length > 0
  );

  const [noCandidatesStatement, candidatesStatement] = useMemo(
    () => [
      `No ${name}s invited yet`,
      `To see the invited ${name}s, head to the "Manage ${name}s" tab`,
    ],
    [name]
  );
  const [
    showOrganizationLimitedModal,
    setShowOrganizationLimitedModal,
  ] = useState<boolean>(false);
  const [statement, setStatement] = useState<string>(
    manageCandidatesExist ? candidatesStatement : noCandidatesStatement
  );

  useEffect(() => {
    if (manageCandidatesExist) {
      setStatement(candidatesStatement);
    } else {
      setStatement(noCandidatesStatement);
    }
  }, [
    manageCandidatesExist,
    candidatesStatement,
    noCandidatesStatement,
    setStatement,
  ]);

  const onParticipantsInvited = (): void => {
    setStatement(candidatesStatement);
  };

  const goToATS = (): void => {
    history.push(`/organization-settings#ats-integrations`);
  };

  const capitalizedName = name
    .split(' ')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');

  const isLD = assessment.purpose === 'ld';

  const gridW = hideAts ? 6 : 4;
  const shareLinkText =
    name === 'candidate'
      ? 'You can share this link with potential candidates for them to click through to take the assessment.'
      : 'You can share this link with your team for them to click through to take the assessment.';

  if (assessment.is_archived) {
    return (
      <div className="no-candidates-container">
        <h2 className="vertical-gap">{statement}</h2>
        {isLD ? (
          <p>Republish the exercise first to be able to invite employees</p>
        ) : (
          <p>Republish the assessment first to be able to invite candidates</p>
        )}
      </div>
    );
  }
  return (
    <div className="no-candidates-container">
      <h2 className="vertical-gap">{statement}</h2>

      <Grid
        className="small-vertical-gap"
        container
        direction="row"
        spacing={3}
      >
        <Grid container item md={gridW}>
          <CardContainer className="card">
            <img
              className="vertical-gap"
              src={AddCandidateLogo}
              alt={`invite ${name}`}
            />
            <h3 className="small-vertical-gap">{`Invite ${capitalizedName}`}</h3>
            <p className="vertical-gap caption">
              {`You can manually invite one ${name} at a time.`}
            </p>
            <Button
              text={`Invite ${capitalizedName}`}
              variant="primary lg"
              onClick={() => {
                if (isLimitedOrganization()) {
                  setShowOrganizationLimitedModal(true);
                  return;
                }
                setShowInviteModal(true);
              }}
            />
            <InviteCandidateModal
              userDetails={userDetails}
              assessment={assessment}
              defaultDaysToComplete={assessment.days_to_complete}
              isShown={showInviteModal}
              setModalVisibility={setShowInviteModal}
              purpose={assessment.purpose}
              initialStep="invite-candidate"
            />
          </CardContainer>
        </Grid>
        <Grid container item md={gridW}>
          <CardContainer className="card">
            <img
              className="vertical-gap"
              src={ImportCandidateLogo}
              alt={`import ${name}s`}
            />
            <h3 className="small-vertical-gap">{`Import ${capitalizedName}s`}</h3>
            <p className="vertical-gap caption">
              {`You can import a file of ${name}s.`}
            </p>
            <Button
              text={`Import ${capitalizedName}s`}
              variant="primary lg"
              onClick={() => {
                if (isLimitedOrganization()) {
                  setShowOrganizationLimitedModal(true);
                  return;
                }
                window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-candidate-import/?test_id=${assessment.id}&purpose=${assessment.purpose}`;
              }}
            />
          </CardContainer>
        </Grid>
        {!hideAts && (
          <Grid container item md={gridW}>
            <CardContainer className="card">
              <img className="vertical-gap" src={AtsLogo} alt="connect ats" />
              <h3 className="small-vertical-gap">Connect to ATS</h3>
              <p className="vertical-gap caption">
                You can connect directly to your applicant tracking system.
              </p>
              <Button
                onClick={goToATS}
                text="Connect to ATS"
                variant="primary lg"
              />
            </CardContainer>
          </Grid>
        )}
      </Grid>

      {link && (
        <CardContainer className="card vertical-gap pinch-horizontal">
          <p className="small-vertical-gap">{shareLinkText}</p>
          <div className="pinch-horizontal-fixed-max">
            <CopyLink value={link} />
          </div>
        </CardContainer>
      )}
      {!isLD && (
        <Grid container className="vertical-gap">
          <Grid item md={12}>
            <SuggestedCandidates
              onParticipantsInvited={onParticipantsInvited}
            />
          </Grid>
        </Grid>
      )}
      <OrganizationLimitedModal
        isShown={showOrganizationLimitedModal}
        setModalVisibility={setShowOrganizationLimitedModal}
        title="Account Limited"
      />
    </div>
  );
};
export default NoCandidates;

import React from 'react';
import { useHistory } from 'react-router-dom';
import authorize from 'Authorization/Check';
import { Modal } from '../Modal';

interface Props {
  isShown?: boolean;
  setModalVisibility?: any;
  title?: string;
  hasActionButton?: boolean;
  actionText?: string;
}
const customProps = {
  actionText: 'Manage Credits',
  showCancel: true,
  cancelButtonText: 'Close',
  containerClass: 'small-modal',
};
const OrganizationLimitedModal = ({
  hasActionButton = true,
  ...props
}: Props): JSX.Element => {
  const history = useHistory();
  const { title } = props;

  if (!authorize('company-billing', 'edit') || !hasActionButton) {
    customProps.actionText = null;
  }

  const clickManageCredits = (): void => {
    history.push('/billing/manage-credits');
  };

  return (
    <Modal handleButtonAction={clickManageCredits} {...customProps} {...props}>
      <div>
        <h2>{title}</h2>
        <br />
        {authorize('company-billing', 'edit') ? (
          <p>
            Your account is currently limited because you’ve run out of credits.
            To proceed, please purchase additional credits to ensure
            uninterrupted access.
          </p>
        ) : (
          <p>
            Your account is currently limited because you’ve run out of credits.
            To proceed, please contact your account owner or admin to purchase
            additional credits to ensure uninterrupted access.
          </p>
        )}
        <hr />
      </div>
    </Modal>
  );
};

export default OrganizationLimitedModal;

import React, { useState } from 'react';
import ImportCandidateIcon from 'images/icons/import-candidates-blue.svg';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import { isLimitedOrganization } from 'helpers/user';
import VerticalDivider from '../../Shared/VerticalDivider';

const ImportCandidatesButton = (props: {
  assessment: any;
  divider: boolean;
}): JSX.Element => {
  const { assessment, divider } = props;
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  const linkTo = `${legacyUrl}/add-candidate-import/?test_id=${assessment.id}&purpose=${assessment.purpose}`;
  const [
    showOrganizationLimitedModal,
    setShowOrganizationLimitedModal,
  ] = useState<boolean>(false);
  const label =
    assessment.purpose === 'ld' ? 'Import Team Members' : 'Import Candidates';
  return (
    <>
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          if (isLimitedOrganization()) {
            setShowOrganizationLimitedModal(true);
            return;
          }
          window.location.href = linkTo;
        }}
        className="button-bar-item"
      >
        <img src={ImportCandidateIcon} alt={label || 'tooltip'} />
        <span className="button-bar-label">{label}</span>
      </div>
      {divider ? <VerticalDivider className="button-bar-divider" /> : ''}
      <OrganizationLimitedModal
        isShown={showOrganizationLimitedModal}
        setModalVisibility={setShowOrganizationLimitedModal}
        title="Account Limited"
      />
    </>
  );
};

export default ImportCandidatesButton;
